html, body, #root {
  min-height: 100vh;
  margin: 0px;
}

#root {
  background-image: url("https://online-portfolio-images.s3.us-east-2.amazonaws.com/public/background.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

a {
  text-decoration: none;
}

.heading {
  height: 50vh;
  color: #113929;
}

.tint {
  background: linear-gradient(#11392900, #113929cc, #113929, #113929, #113929, #113929, #113929cc, #113929cc);
}

.blur {
  background-color: #A6B3AD99;
  backdrop-filter: blur(4px);
  color: #010E09;
  transition: 0.3s;
}


/* Project page stuff */
.tile:hover {
  box-shadow: #010E09 0px 5px 15px;
  backdrop-filter: blur(10px);
}

.ProjectDisplay .body {
  white-space: pre-line;  
  vertical-align: bottom;
}


/* Footer */
.Footer {
  height: 250px;
  color: #113929;
  background: linear-gradient(#113929cc, #A6B3AD, #A6B3AD, #A6B3AD);
}

.Footer a:hover {
  color: white;
}

.Footer img {
  mix-blend-mode: multiply;
}

.Footer a {
  color: #113929;
  transition: 0.3s;
}


/* Buttons? */
.btn {
  background-color: #113929;
  color: #A6B3AD;
}

.btn:hover {
  color: #010E09;
  background-color: #A6B3AD;
}

.btn:active {
  background-color: white !important;
  color: #010E09 !important;
  border: none !important;
}

.Home .btn {
  background-color: #A6B3AD;
  color: #113929;
}

.Home .btn:hover {
  background-color: #010E09;
  color: #A6B3AD;
}


/* Navbar */
.navbar-nav > .nav-item > .on {
  color: #113929;
}

.navbar-nav > .nav-item > a {
  color: #A6B3AD;
}

.navbar-nav > .nav-item > a:hover {
  color: #113929;
}


/* Color scheme */
.red {
  background-color: #DF2935;
}

.orange {
  background-color: #C05746;
}

.blue {
  background-color: #578F8C;
}

.red-color {
  color: #DF2935;
}

.ash-text {
  color: #A6B3AD;
}

.night-text {
  color: #010E09;
}

.green-text {
  color: #113929;
}

.burgundy-text {
  color: #70161E;
}

.ash {
  background-color: #A6B3AD;
}

.night {
  background-color: #010E09;
}

.green {
  background-color: #113929;
}


/* Fixing issue of bootstrap icons being off-balance vertically in buttons 
and making them look better in general as well. source: https://github.com/twbs/icons/issues/82 */
svg {
  vertical-align: -0.125em;
  margin-bottom: calc(-0.125em / 2);
}

.btn svg {
    transform: scale(1.5);
    margin-bottom: 0;
}

.double-space {
  white-space: pre;
}